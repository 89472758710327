<template>
  <div class="box">
    <div class="list-box" v-for="l in list" :key="l.msgId">
      <div class="time">{{ l.appStationMsg.gmtCreate }}</div>
      <div class="list">
        <div class="title">{{ l.appStationMsg.title }}</div>
        <div class="nr">{{ l.appStationMsg.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      list: {},
    };
  },
  created() {
    this.$http.get(url.getStationMsg).then((res) => {
      if (res.code == 200) {
        this.$http.get(url.listAndStation).then((data) => {
          this.list = data.data.content;
        });
      }
    });
  },
  methods: {
    toInfo(index) {
      this.$router.push({
        path: "caseInfo",
        query: { index: index },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  padding-top: 66px;
  padding-bottom: 50px;
  min-height: calc(100vh - 120px);
  .list-box {
    width: 345px;
    margin: 0 auto;
    .time {
      color: #999999;
      font-size: 11px;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 15px;
    }
    .list {
      border-radius: 10px;
      overflow: hidden;
      background: #ffffff;
      padding: 10px;
      .title {
        color: #101010;
        font-size: 14px;
        font-weight: bold;
      }
      .nr {
        color: #666666;
        font-size: 12px;
        margin-top: 16px;
      }
    }
  }
}
</style>

